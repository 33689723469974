import dom from '../../../../../wrapper/DomWrapper';

export default function connectEcwidScript(storeId, { onLoad = () => null, onError = () => null }) {
  const ecwidSript = document.createElement('script');

  ecwidSript.id = 'store-script';
  ecwidSript.type = 'text/javascript';
  ecwidSript.charset = 'utf-8';
  ecwidSript.src = `https://app.shopsettings.com/script.js?${storeId}`;
  ecwidSript.defer = true;
  ecwidSript.dataset.cfasync = 'false';
  ecwidSript.onload = onLoad;
  ecwidSript.onerror = onError;

  dom.document.head.appendChild(ecwidSript);
}
