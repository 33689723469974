import dom from '../../../../../wrapper/DomWrapper';
import { hideBodyScroll, restoreBodyScroll } from '../../../../../helpers/setBodyClasses';
import {
  ESC_KEY_CODE,
  ECWID_GALLERY_SELECTOR,
  ECWID_GALLERY_MODAL_SELECTOR,
  ECWID_GALLERY_PICTURE_SELECTOR,
  ECWID_GALLERY_NAV_LEFT_SELECTOR,
  ECWID_GALLERY_NAV_RIGHT_SELECTOR,
  ECWID_GALLERY_MORE_PICTURES_SELECTOR,
} from '../constants';

export default () => {
  const elGallery = dom.getElement(ECWID_GALLERY_SELECTOR);

  if (!elGallery) return;

  dom.on(elGallery, 'click', (event) => {
    const { target } = event;

    if (target.closest(ECWID_GALLERY_PICTURE_SELECTOR)
      || target.closest(ECWID_GALLERY_MORE_PICTURES_SELECTOR)) hideBodyScroll();
  });

  const elGalleryModal = dom.getElement(ECWID_GALLERY_MODAL_SELECTOR);

  if (!elGalleryModal) return;

  dom.on(elGalleryModal, 'click', (event) => {
    const { target } = event;
    const isClickOnNav = target.closest(ECWID_GALLERY_NAV_LEFT_SELECTOR)
      || target.closest(ECWID_GALLERY_NAV_RIGHT_SELECTOR);

    if (isClickOnNav) return;

    restoreBodyScroll();
  });

  dom.on(elGalleryModal, 'keydown', (event) => {
    if (event?.which !== ESC_KEY_CODE) return;

    restoreBodyScroll();
  });

  dom.on(elGalleryModal, 'wheel', () => restoreBodyScroll());
};
