import entries from 'lodash/entries';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import memoize from 'lodash/memoize';

import { DEFAULT_PRODUCT_SORT_BY } from '../../../../../../components/Ecommerce/Ecwid/Custom/constants';
import { lightness } from '../../../../../helpers/color';
import getRoot from '../../../../../helpers/color/utils/getRoot';
import getStateValue from '../../../../../helpers/getStateValue';
import dom from '../../../../../wrapper/DomWrapper';
import { DEFAULT_CURRENCY_FORMAT_OPTIONS } from '../../constants';
import Product from '../../entities/product.model';
import {
  getPriceWithCurrency,
  getSavedOnDiscountInPercent,
  prepareDataWithMap,
} from '../../utils';
import { getProductPrice } from '../ecwidProvider/utils';

import ROUTES from './constants/routes';
import { CATEGORIES_DATA_MAP_KEYS } from './constants';
import DashCart from './DashCart';
import { sortProducts } from './utils';

class DashProvider {
  constructor() {
    this.storeId = getStateValue('ecommerce.storeId');

    this.cart = new DashCart(this);

    this.fetchProductMemoize = memoize(async (instanceId) => {
      const productsResponse = await dom.window.fetch(ROUTES.allProducts(instanceId));

      if (!productsResponse.ok) throw new Error(productsResponse.status.toString(10));

      return productsResponse.json();
    });

    this.fetchProductsMemoize = memoize(async (instanceId, params) => {
      const productsResponse = await dom.window.fetch(ROUTES.allProducts(instanceId, params));

      if (!productsResponse.ok) throw new Error(productsResponse.status.toString(10));

      return productsResponse.json();
    });
    const OnAPILoaded = async () => {
      this.formatAndUnits = await this.getFormatAndUnits();

      this.apiLoaded = true;

      if (isFunction(this.apiLoadedResolver)) {
        this.apiLoadedResolver(true);
      }
    };

    OnAPILoaded();
  }

  isReady = memoize(() => new Promise((resolve) => {
    if (this.apiLoaded) {
      resolve(true);

      return;
    }

    this.apiLoadedResolver = resolve;
  }));

  getCategories = async (params) => {
    const extendedParams = isPlainObject(params) ? { ...params } : {};

    await this.isReady();

    const response = await dom.window.fetch(ROUTES.categories(this.storeId));

    if (!response.ok) throw new Error(response.status.toString(10));

    const responseData = await response.json();
    const prepareData = prepareDataWithMap(CATEGORIES_DATA_MAP_KEYS, responseData);

    return {
      ...prepareData,
      total: prepareData.items.length,
      ...extendedParams,
    };
  };

  getProduct = async (id, productPage = false) => {
    await this.isReady();

    const productsData = !productPage ? await this.fetchProductMemoize(this.storeId) : {};

    let product = (productsData.products || []).find((el) => el?.id === id);

    if (!product) {
      const productData = await dom.window.fetch(ROUTES.product(this.storeId, id));

      if (!productData.ok) throw new Error(productData.status.toString(10));

      const data = await productData.json();

      product = data.product;
    }

    return this.transformProduct(product);
  };

  getProducts = async (params) => {
    await this.isReady();

    const extendedParams = isPlainObject(params) ? { ...params } : {};

    if (isNil(extendedParams.sortBy)) {
      extendedParams.sortBy = DEFAULT_PRODUCT_SORT_BY; // default sortBy
    }

    const productsData = await this.fetchProductsMemoize(this.storeId, extendedParams);

    const { products = [], total_product_count: total } = productsData;
    const transformedItems = products.map((productData) => this.transformProduct(productData));

    const filterEmptyOptions = transformedItems.filter((el) => {
      const isParent = el.isParentProduct;

      if (!isParent) return true;

      return el.options?.length > 0 && el.options[0]?.products?.length > 0;
    });

    const updatedData = (data) => {
      const {
        offset = 0,
        limit = 0,
        items,
      } = data;

      const sortedItems = sortProducts(extendedParams.sortBy, items || []);

      return {
        ...data,
        // eslint-disable-next-line unicorn/explicit-length-check
        total: sortedItems.length,
        items: sortedItems.slice(offset, (offset + limit)),
      };
    };

    return updatedData({ ...extendedParams, items: filterEmptyOptions, total });
  };

  clearFetchProductCache = () => {
    if (!isFunction(this.fetchProductMemoize?.cache?.clear)) return;

    this.fetchProductMemoize.cache.clear();
  };

  clearFetchProductsCache = () => {
    if (!isFunction(this.fetchProductsMemoize?.cache?.clear)) return;

    this.fetchProductsMemoize.cache.clear();
  };

  transformProduct = (productData) => {
    const product = new Product();
    const {
      id: productId,
      product_type: productType,
      name,
      short_description: excerpt,
      description,
      stock: quantity,
      price,
      sku,
      product_category_ids: categoryIds,
      limit,
      unlimited,
      images,
      original_price: originalPrice,
      product_variant_options: variantOptions,
      product_variants: productVariants,
      fulfillment_type: fulfillmentType,
      pickup_prep_time_unit: pickupPrepTimeUnit,
      pickup_prep_time_length: pickupPrepTimeLength,
      is_variant_available: isVariantAvailable,
      meta_title: metaTitle,
      meta_description: metaDescription,
      is_subscription_available: isSubscriptionAvailable,
      subscription_details: subscriptionDetails,
      banner,
    } = productData;

    const isDigital = productType === 2;
    const prepareQuantity = isDigital && quantity > 1 ? 1 : quantity;
    const compareToPrice = (originalPrice !== price && originalPrice) || null;
    const inStock = prepareQuantity > 0;
    const brandColor = getRoot('var(--color_brand)');
    const isLightBrandColor = !!lightness(brandColor);

    product.id = productId;
    product.name = name;
    product.isParentProduct = isVariantAvailable;
    product.title = name;
    product.excerpt = excerpt;
    product.description = description;
    product.quantity = prepareQuantity;
    product.price = price;
    product.priceFormatted = this.formatPrice(price);
    product.priceWithOptions = this.getPrice(price, 1);
    product.priceWithOptionsFormatted = this.formatPrice(product.priceWithOptions);
    product.compareToPriceWithOptions = this.getPrice(compareToPrice, 1);
    product.compareToPrice = compareToPrice;
    product.compareToPriceFormatted = this.formatPrice(compareToPrice);
    // eslint-disable-next-line max-len
    product.compareToPriceWithOptionsFormatted = this.formatPrice(product.compareToPriceWithOptions);
    product.discountFormatted = '';
    product.sku = sku;
    product.category = categoryIds;
    product.limit = limit;
    product.unlimited = !!unlimited;
    product.inStock = inStock;
    product.isDigital = isDigital;
    product.images = (images || []).filter((el) => !el.is_stock).map((image) => ({
      imageUrl: image.source,
    }));
    product.originalImage = { src: '' };
    product.options = this.prepareProductOptions(variantOptions, productVariants);
    product.shipping = this.prepareProductShipping(fulfillmentType, {
      pickupPrepTimeUnit,
      pickupPrepTimeLength,
    }, product.getTranslate);
    product.metaTitle = metaTitle;
    product.metaDescription = metaDescription;

    // product with variants can't have a subscription
    product.isSubscriptionAvailable = isSubscriptionAvailable && !isVariantAvailable;
    product.subscriptionDetails = subscriptionDetails;
    product.subscriptionTitle = subscriptionDetails?.name || '';
    product.subscriptionText = subscriptionDetails?.description || '';
    product.subscriptionDiscount = subscriptionDetails?.discount_percentage || 0;
    product.withSubscriptionDiscount = !!product.subscriptionDiscount;

    product.banner = banner;
    product.bannerAvailable = !!banner && banner?.trim()?.length > 0;
    product.bannerColor = isLightBrandColor ? 'var(--color_text)' : 'var(--color_textAlt)';
    product.checkWithOptions = () => !!(product.options?.length > 0 && product.options[0]?.products?.length > 0);

    if (product.compareToPrice) {
      product.discountFormatted = `${this.getSavedPercent(product.priceWithOptions, product.compareToPriceWithOptions)}%`;
    }

    const defaultProduct = product.options ? (
      product.options[0]?.defaultProduct
      || {
        quantity: 0,
        inStock: false,
      }
    ) : {};

    entries(defaultProduct).forEach(([key, value]) => {
      product[key] = value;
    });

    return product;
  };

  formatPrice = (price, formatAndUnits = this.formatAndUnits) => getPriceWithCurrency(price, formatAndUnits);

  getFormatAndUnits = async () => {
    try {
      const currencyResponse = await dom.window.fetch(ROUTES.currency(this.storeId));

      if (!currencyResponse.ok) throw new Error(currencyResponse.status.toString(10));

      const currencyData = await currencyResponse.json();
      const { code: currency, symbol: currencyPrefix } = currencyData?.settings?.supportedCurrencies?.default || {};

      return {
        ...DEFAULT_CURRENCY_FORMAT_OPTIONS,
        ...(!!currency && { currency }),
        ...(!!currencyPrefix && { currencyPrefix }),
      };
    } catch (error) {
      console.error(error);

      return DEFAULT_CURRENCY_FORMAT_OPTIONS;
    }
  };

  getPreviousPrice = (price, compareToPrice, quantity = 1) => {
    if (!compareToPrice) return null;

    return getProductPrice(compareToPrice, quantity);
  };

  getPrice = (price, quantity = 1, options) => getProductPrice(price, quantity, options);

  getSavedPercent = (totalPrice, previousPrice) => {
    if (!totalPrice || !previousPrice) return null;

    return getSavedOnDiscountInPercent(totalPrice, previousPrice);
  };

  prepareProductOptions = (variantOptions, productVariants) => {
    if (!variantOptions || !productVariants) return variantOptions;

    const mapProductVariants = productVariants.map((variant) => {
      const variations = Object.keys(variant).reduce((acc, key) => {
        if (!key.includes('variation_value')) return acc;

        const index = key.replace('variation_value', '');
        const variation = variant[key];

        if (!variation || variation.variation_option_id === 0) return acc;

        return {
          ...acc,
          [index]: {
            choiceId: variation.variation_option_id,
          },
        };
      }, {});

      return {
        ...this.transformProduct(variant),
        variations,
      };
    });

    const withVariations = mapProductVariants.filter((el) => !isEmpty(el.variations));
    const filteredByQuantity = withVariations.filter((el) => el.quantity > 0);
    const withAllOptions = withVariations.filter(
      (el) => Object.keys(el.variations).length === variantOptions.length
    );

    if (withAllOptions.length === 0) return [];

    const variants = variantOptions.map((variant) => this.transformVariants({
      ...variant,
      products: withAllOptions,
    }));

    const defaultProduct = (
      filteredByQuantity.length > 0
        ? filteredByQuantity
        : withAllOptions
    ).sort((a, b) => a.price - b.price)[0];

    return [
      {
        type: 'RELATED',
        variants,
        products: withAllOptions,
        ...(defaultProduct && {
          defaultProduct: this.transformDefaultProduct(defaultProduct),
        }),
      },
    ];
  };

  prepareProductShipping = (type, {
    pickupPrepTimeUnit,
    pickupPrepTimeLength,
  } = {}, getTranslate) => {
    const unitPrefix = getTranslate(pickupPrepTimeUnit === 1
      ? 'se.wf.ecom_product_dash_shipping_hours'
      : 'se.wf.ecom_product_dash_shipping_days');

    if (type === 1) {
      return [{
        label: getTranslate('se.wf.ecom_product_dash_shipping_price'),
      }];
    }
    if (type === 2) {
      return [{
        label: getTranslate('se.wf.ecom_product_dash_shipping_none'),
      }];
    }
    if (type === 6) {
      return [{
        label: getTranslate('se.wf.ecom_product_dash_shipping_free'),
      }];
    }
    if (type === 3) {
      return [{
        label: getTranslate('se.wf.ecom_product_dash_shipping_local'),
        value: `${pickupPrepTimeLength}`,
        valuePrefix: unitPrefix,
      }];
    }
    if (type === 4) {
      return [
        {
          label: getTranslate('se.wf.ecom_product_dash_shipping_price'),
        },
        {
          label: getTranslate('se.wf.ecom_product_dash_shipping_locals'),
          value: `${pickupPrepTimeLength}`,
          valuePrefix: unitPrefix,
        },
      ];
    }
  };

  transformVariants = (variant) => {
    const {
      name,
      product_variant_choices: choices,
      products,
    } = variant;

    const prepareChoices = choices.reduce((acc, choice) => {
      const filteredChoices = acc;

      const productsHasChoice = products?.reduce(
        (accum, product) => {
          const findProducts = accum;
          const productHasChoice = Object.values(product.variations).some(
            (variation) => variation.choiceId === choice.id
          );

          if (productHasChoice) findProducts.push(product);

          return findProducts;
        },
        []
      );

      if (productsHasChoice.length === 0) return acc;

      filteredChoices.push(
        {
          id: choice.id,
          text: choice.display_value,
          disabled: !productsHasChoice.some((el) => el?.quantity > 0 || el?.unlimited),
        }
      );

      return filteredChoices;
    }, []);

    return {
      name,
      choices: prepareChoices,
    };
  };

  transformDefaultProduct = (product) => {
    const {
      id,
      quantity,
      weight,
      inStock,
      isShippingRequired,
      unlimited,
      price,
      priceFormatted,
      priceWithOptions,
      priceWithOptionsFormatted,
      compareToPriceWithOptions,
      compareToPrice,
      compareToPriceFormatted,
      compareToPriceWithOptionsFormatted,
    } = product;

    return {
      defaultId: id,
      quantity,
      weight,
      inStock,
      isShippingRequired,
      unlimited,
      price,
      priceFormatted,
      priceWithOptions,
      priceWithOptionsFormatted,
      compareToPriceWithOptions,
      compareToPrice,
      compareToPriceFormatted,
      compareToPriceWithOptionsFormatted,
    };
  };
}

export default DashProvider;
