import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import isFunction from 'lodash/isFunction';

import dom from '../../../../../wrapper/DomWrapper';

function initStorefront(containerId, type, args = []) {
  let watchIntervalId = null;

  const initWatcher = ({
    obj, propName, handler, timeout = 1500,
  } = {}) => {
    if (
      !isNil(watchIntervalId)
      || !isPlainObject(obj)
    ) return;

    watchIntervalId = setInterval(
      () => isFunction(handler) && handler(obj[propName]),
      timeout,
    );
  };

  const resetWatcher = () => {
    if (isNil(watchIntervalId)) return;

    clearInterval(watchIntervalId);
    watchIntervalId = null;
  };

  return new Promise((resolve, reject) => {
    const instance = dom.window.Ecwid;

    if (!instance) reject(new Error('Ecwid instance not found'));

    if (isFunction(instance.destroy)) instance.destroy();

    // eslint-disable-next-line no-underscore-dangle
    dom.window._xnext_initialization_scripts = [
      {
        widgetType: type,
        id: containerId,
        arg: args,
      },
    ];
    instance.init();
    dom.window.ecwid_onBodyDone();

    const cb = (page) => resolve(page);

    initWatcher({
      obj: window.ecwid_initial_data?.data,
      propName: 'storeClosed',
      handler: (value) => {
        if (isNil(value)) return;

        resetWatcher();
        cb();
      },
    });

    instance.OnPageLoaded.add((page) => {
      resetWatcher();
      cb(page);
    });
  });
}

export default initStorefront;
