import dom from '../../../wrapper/DomWrapper';
import { CART_CLASS, HEADER_LAYOUT_WRAPPER, LOGO_CLASS } from '../constants';

export default (selector) => {
  const elements = Array.from(dom.getCollection(`${HEADER_LAYOUT_WRAPPER}`, selector));
  const isSingle = elements.length === 1;

  return elements
    .reduce((acc, el, index) => {
      const isFirst = index === 0;
      const isLast = index === elements.length - 1;

      const isLogo = dom.hasClass(el, LOGO_CLASS);
      const isCart = dom.hasClass(el, CART_CLASS);

      if (isLogo) {
        if (isFirst) acc.isLogoFirst = true;
        else if (isLast) acc.isLogoLast = true;
        else acc.isLogoCenter = true;
      }

      if (isCart) {
        if (isFirst) acc.isCartFirst = true;
        else if (isLast) acc.isCartLast = true;
        else acc.isCartCenter = true;
      }

      return acc;
    }, {
      isLogoFirst: false,
      isCartFirst: false,
      isLogoCenter: false,
      isCartCenter: false,
      isLogoLast: false,
      isCartLast: false,
      isSingle,
    });
};
